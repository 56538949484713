<template>
  <div class="action__inner">
    <a-form :model="dataSource" :rules="rules" @finish="sendBuy" name="deposit" ref="buy">
      <a-form-item ref="personal" name="personal" class="input__wrapper" :label="i18n.t('start.personal')">
        <a-input
          style="width: 100%"
          :value="
            i18n.t('start.inputAccount', {
              name: i18n.t('start.name' + mainAccount.attributes.kind),
              amount: mainAccount.attributes.amount,
            })
          "
          :disabled="true"
          size="large"
          type="text"
        />
      </a-form-item>
      <a-form-item ref="amount" name="amount" class="input__wrapper" :label="i18n.t('start.summ')">
        <a-input
          v-model:value="dataSource.amount"
          size="large"
          style="width: 100%"
          type="number"
          :required="true"
          step="0.01"
          @change="updateCryptoAmount"
        >
          <template #addonAfter>
            <p>{{ i18n.t('transactions.USDT') }}</p>
          </template>
        </a-input>
        <p style="display: flex">
          <a-tooltip placement="right" :title="i18n.t('accounts.token.exchange_rate', { token: cryptoAmount })">
            {{ i18n.t('start.total') }}: {{ (cryptoAmount * dataSource.amount).toFixed(2) }} MTH
          </a-tooltip>
        </p>
      </a-form-item>
      <sdButton
        class="action__button"
        size="large"
        type="primary"
        :disabled="isLoading || errorAmount"
        htmlType="submit"
        raised
      >
        {{ i18n.t('start.buy') }}
      </sdButton>
      <!--<p class="action__more-info" @click="changePayInfo">
              <sdFeatherIcons type="info" size="20" /> {{ i18n.t('start.moreInfo') }}
            </p>-->
    </a-form>
  </div>
  <!--<div class="action__inner" v-if="visiblePayHint">
          <p class="action__info">
            {{ i18n.t('start.actionDepositHintFirst') }} <br />
            {{ i18n.t('start.actionDepositHintSecond') }} <br />
            {{ i18n.t('start.actionDepositHintThird') }} <br />
            {{ i18n.t('start.actionDepositHintFourth') }} <br />
            {{ i18n.t('start.actionDepositHintFifth') }}
            <router-link :to="{ name: 'faq', params: { modal: true } }">{{
              i18n.t('start.actionDepositHintForm')
            }}</router-link>
          </p>
        </div>-->
</template>

<script>
import { computed, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { depositCurrencies } from '@/config/helpers/JSONdata';
import { useRoute } from 'vue-router';

const DepositModal = {
  name: 'DepositModal',
  props: { error: Boolean },
  emits: ['dataNotSended', 'dataSended'],
  setup(props, { emit }) {
    const i18n = useI18n();
    const { state, dispatch } = useStore();
    const route = useRoute();

    const dataSource = ref({
      amount: '',
    });
    const errorAmount = ref(false);
    const buy = ref();

    const isLoading = computed(() => state.accounts.fileLoading);
    const cryptoAmount = computed(() => state.accounts.cryptoAmount);
    const mainAccount = computed(() => state.accounts.mainAccount);

    const amountValidation = () => {
      if (!rules.amount[0].trigger[1]) {
        rules.amount[0].trigger[1] = 'change';
      }
      let max = 1000000;
      const validate = { min: 10, max };
      if (validate.min > dataSource.value.amount || validate.max < dataSource.value.amount) {
        errorAmount.value = true;
        return Promise.reject(
          i18n.t('messages.errorAmount', validate, dataSource.value.amount > validate.max ? 2 : 1) + ' USDT',
        );
      } else {
        errorAmount.value = false;
        return Promise.resolve();
      }
    };

    const rules = reactive({
      amount: [
        {
          required: true,
          validator: amountValidation,
          trigger: ['blur'],
        },
      ],
    });
    const updateCryptoAmount = () => {};

    const sendBuy = async () => {
      await dispatch('buyMTHTokens', {
        account_from_id: mainAccount.value.id,
        account_to_id: route.params.accId,
        amount: dataSource.value.amount,
      });
      if (props.error) {
        emit('dataNotSended');
      } else {
        emit('dataSended');
      }
    };

    return {
      depositCurrencies,
      i18n,
      dataSource,
      errorAmount,
      isLoading,
      cryptoAmount,
      mainAccount,
      rules,
      buy,
      updateCryptoAmount,
      sendBuy,
    };
  },
};
export default DepositModal;
</script>

<style></style>
